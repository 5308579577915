<template>
  <div>
    <h1>当前版本v2.0.0</h1>
    <h2>
      历史更新记录
    </h2>
    <div>
      <div>
        <h3>
          v2.0.0
        </h3>
        <p>2022年10月8日</p>
        <p>切换v2版本</p>
      </div>
      <div>
        <h3>
          v1.11.3
        </h3>
        <p>2022年8月11日</p>
        <p>CI-CD自动部署</p>
      </div>
      <div>
        <h3>
          v1.11.2
        </h3>
        <p>2022年8月2日</p>
        <p>删除使用说明书</p>
      </div>
      <div>
        <h3>
          v1.11.1
        </h3>
        <p>2022年7月29日</p>
        <p>新增使用说明书</p>
      </div>
      <div>
        <h3>
          v1.11.0
        </h3>
        <p>2022年7月27日</p>
        <p>帐号使用者功能</p>
      </div>
      <div>
        <h3>
          v1.10.3
        </h3>
        <p>2022年7月21日</p>
        <p>头像、首页等样式优化</p>
      </div>
      <div>
        <h3>
          v1.10.2
        </h3>
        <p>2022年6月6日</p>
        <p>管理员表格样式</p>
      </div>
      <div>
        <h3>
          v1.10.1
        </h3>
        <p>2022年5月27日</p>
        <p>修复主页bug</p>
      </div>
      <div>
        <h3>
          v1.10.0
        </h3>
        <p>2022年5月26日</p>
        <p>1.样式大量调整</p>
        <p>2.新增主页统计</p>
      </div>
      <div>
        <h3>
          v1.9.4
        </h3>
        <p>2022年4月18日</p>
        <p>任务展示新增用户信息</p>
      </div>
      <div>
        <h3>
          v1.9.3
        </h3>
        <p>2022年4月1日</p>
        <p>素材相关用户快捷性使用优化</p>
      </div>
      <div>
        <h3>
          v1.9.2
        </h3>
        <p>2022年3月30日</p>
        <p>新增素材相关提示</p>
      </div>
      <div>
        <h3>
          v1.9.1
        </h3>
        <p>2022年3月29日</p>
        <p>提示高风险推文</p>
      </div>
      <div>
        <h3>
          v1.9.0
        </h3>
        <p>2022年3月24日</p>
        <p>素材统一管理</p>
      </div>
      <div>
        <h3>
          v1.8.1
        </h3>
        <p>2022年3月16日</p>
        <p>优化其他使用问题</p>
      </div>
      <div>
        <h3>
          v1.8.0
        </h3>
        <p>2022年3月16日</p>
        <p>1.新增用户积分系统</p>
        <p>2.新增订单服务系统</p>
        <p>3.新增发帖限制解释说明</p>
        <p>4.优化其他使用问题</p>
      </div>
      <div>
        <h3>
          v1.7.3
        </h3>
        <p>2022年3月8日</p>
        <p>优化表格复制操作</p>
      </div>
      <div>
        <h3>
          v1.7.2
        </h3>
        <p>2022年3月7日</p>
        <p>Facebook新增发帖后链接</p>
      </div>
      <div>
        <h3>
          v1.7.1
        </h3>
        <p>2022年2月28日</p>
        <p>1 Facebook新增截图下载功能</p>
        <p>2 任务创建取消默认时间</p>
        <p>3 各平台帐号新增登录帐号列</p>
      </div>
      <div>
        <h3>
          v1.7.0
        </h3>
        <p>2022年2月25日</p>
        <p>1 YouTube功能相关</p>
        <p>2 管理相关功能优化</p>
      </div>
      <div>
        <h3>
          v1.6.3
        </h3>
        <p>2022年2月17日</p>
        <p>新增帐号自定义备注功能</p>
      </div>
      <div>
        <h3>
          v1.6.2
        </h3>
        <p>2022年2月14日</p>
        <p>优化帐号分配</p>
      </div>
      <div>
        <h3>
          v1.6.1
        </h3>
        <p>2021年12月24日</p>
        <p>优化状态提示</p>
      </div>
      <div>
        <h3>
          v1.6.0
        </h3>
        <p>2021年12月21日</p>
        <p>新增发帖后截屏并提供下载功能</p>
      </div>
      <div>
        <h3>
          v1.5.0
        </h3>
        <p>2021年12月13日</p>
        <p>管理员帐号操作优化</p>
      </div>
      <div>
        <h3>
          v1.4.2
        </h3>
        <p>2021年11月17日</p>
        <p>转帖时允许评论</p>
      </div>
      <div>
        <h3>
          v1.4.1
        </h3>
        <p>2021年11月4日</p>
        <p>管理端信息优化</p>
      </div>
      <div>
        <h3>
          v1.4.0
        </h3>
        <p>2021年11月2日</p>
        <p>Facebook发帖功能上线</p>
      </div>
      <div>
        <h3>
          v1.3.3
        </h3>
        <p>2021年11月1日</p>
        <p>1. 优化组件逻辑</p>
        <p>2. 修复任务提及框不重置的问题</p>
        <p>3. 调整任务时间限制</p>
        <p>4. 修复推文链接验证问题</p>
      </div>
      <div>
        <h3>
          v1.3.2
        </h3>
        <p>2021年10月27日</p>
        <p>发帖任务允许只发表多媒体文件</p>
      </div>
      <div>
        <h3>
          v1.3.1
        </h3>
        <p>2021年10月25日</p>
        <p>1 推文、评论长度限制</p>
        <p>2 优化第三方用户登陆状态失效后的体验</p>
        <p>3 优化表格因未知状态崩溃的问题</p>
      </div>
      <div>
        <h3>
          v1.3.0
        </h3>
        <p>2021年10月22日</p>
        <p>1 新增点赞任务</p>
        <p>2 新增评论任务</p>
        <p>3 新增批量删除任务功能</p>
        <p>4 各任务新增多种异常情况</p>
      </div>
      <div>
        <h3>
          v1.2.1
        </h3>
        <p>2021年10月20日</p>
        <p>修复管理员路由不能持久的问题</p>
      </div>
      <div>
        <h3>
          v1.2.0
        </h3>
        <p>2021年10月20日</p>
        <p>1 新增视频上传支持</p>
        <p>
          2 新增上传文件限制
        </p>
      </div>
      <div>
        <h3>
          v1.1.2
        </h3>
        <p>2021年10月19日</p>
        <p>修复批量任务上传BUG</p>
      </div>
      <div>
        <h3>
          v1.1.1
        </h3>
        <p>2021年10月19日</p>
        <p>1 新增页面刷新按钮</p>
        <p>2 修复图片上传bug</p>
      </div>
      <div>
        <h3>
          v1.1.0
        </h3>
        <p>2021年9月29日</p>
        <p>新增twitter帐号管理功能</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
